import moment from "moment";
import React, { Component } from "react";
import Dialog from "react-bootstrap-dialog";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Api from "../../../core/Api";
import {
  doLogout,
  doRenew,
  loadNotifications,
  saveLayout,
} from "../../App/actions";
import axios from "axios";
import { getData } from "../../../core/utils/localStorage";
import { getDashboardData } from "../../Dashboard/actions";
import WidgetUpdate from "./WidgetUpdate";
import DarkToggle from "../../../components/dashboards/DarkToggle";
import defaultImage from "../../../Assets/images/default.jpg";
import SwitchOrganization from "./SwitchOrganization";

// import bellGreyUrl from '../../../Assets/images/notification_grey_ico.png';
// import bellWhiteUrl from '../../../Assets/images/notification_white_ico.png';
let notificationTime = "";

const loadImageError = (e) => {
  e.target.src = `${Api.request.ROOT_API_URL}/SION/images/profiles/default.jpg`;
};
const displayNotification = ({ description, name }) => {
  if (description && description.length < 250) return description;
  return name;
};

const getTimedNotif = (d) => {
  if (d && d.length > 0) {
    var topnotif = d.slice(0, 10);

    if (!(topnotif instanceof Array)) {
      return;
    }
    // eslint-disable-next-line array-callback-return
    topnotif.map((obj) => {
      if (!notificationTime) {
        //set the notification time
        notificationTime = obj.created;
      }
      //only show notifications for new events ago
      if (moment(notificationTime).diff(obj.created, "seconds") < 0) {
        var ntype = obj.type === "login" ? "info" : "warning";
        if (obj.type === "login") {
          toast(obj.name + ": " + obj.description, {
            autoClose: 2000,
            type: ntype,
          });
        } else {
          toast(obj.name + ": " + obj.description, {
            autoClose: 7000,
            type: ntype,
          });
        }

        notificationTime = obj.created;
        //toast( 'Test', { autoClose: 1500, type: 'info' });
        return moment(d.created).fromNow();
      }
    });
  }
};
/* const showLogoutModal = (obj) => {
  var lltime = setInterval(function() { obj.props.doLogout(); }, 30000);
  obj.dialog.show({
    title: "Session expiring...",
    body: "Your session is about to expire. Please continue to resume or logout to exit",
    actions: [
      Dialog.Action(
        'Logout',
        () => {
          obj.props.doLogout();
      },
        'btn-default'
      ),
      Dialog.Action(
        'Continue',
        () => {
          obj.props.doRenew();
          clearInterval(lltime);
      },
        'btn-primary'
      )
    ]
  });
}; */

class Navbar extends Component {
  state = {
    settingOpen: false,
    notificationOpen: false,
    authorizedOrgs: [],
    notifications: [],
    users: [],
    page: 0,
    open: false,
  };

  static getDerivedStateFromProps(props, state) {
    return {
      notifications: props.notifications,
      users: props.users,
    };
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClick() {
    // this.onOpenModal(); -->if you want modal enable this
    this.props.doLogout();
    // setTimeout(this.props.doLogout().bind(this),15000);
  }
  componentDidMount() {
    this.refreshNofications();
    this.intervalLock = setInterval(this.refreshNofications, 30000);
    this.intervalLock2 = setInterval(this.showModal, 780000);
  }
  componentWillUnmount() {
    if (this.intervalLock) clearInterval(this.intervalLock);
  }
  showModal = () => {
    this.props.doRenew();
  };

  refreshNofications = () => {
    this.props.loadNotifications();
    getTimedNotif(this.props.notifications);
  };
  toggleNotification = () => {
    this.setState({
      notificationOpen: !this.state.notificationOpen,
      settingOpen: false,
    });
  };
  toggleSetting = () => {
    this.setState({
      settingOpen: !this.state.settingOpen,
      notificationOpen: false,
    });
  };
  setLayout = () => {
    this.props.saveLayout(this.props.layout);
  };
  resetLayout = () => {
    this.props.saveLayout(this.props.grid);
    toast("Widget layout will reset shortly", {
      autoClose: 2000,
      type: "info",
    });
  };

  toggleTheme = () => {
    this.setState((state) => ({
      isChangeClicked: !state.isChangeClicked,
    }));
  };

  showWidgetList = () => {
    this.setState({ showModal: true });
  };

  handleHelmetChange = (newState) => {
    const { metaTags } = newState;
    const tag = metaTags.find((e) => e.name === "pagename");
    const pageTitle = tag ? tag.content : "Dashboard";
    this.setState({ pageTitle });
  };
  loadMore = () => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  showAccessModal = () => {
    const persist = getData("access_token");
    axios
      .get(`${Api.request.ROOT_API_URL}/authorizedorgs/`, {
        headers: {
          Authorization: `Bearer ${persist}`,
        },
      })
      .then((success) => {
        this.setState({ authorizedOrgs: success.data });
        this.setState({ accessModal: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClose = () => {
    this.setState({ accessModal: false });

    // this.setState({ showModal: false }); //this is for widget layout update
  };

  render() {
    const {
      page,
      settingOpen,
      notificationOpen,
      pageTitle = "Dashboard",
      notifications,
    } = this.state;
    const {
      user: { firstname, lastname, access, sroleid },
    } = this.props;
    const user = this.props.user;
    const image = `${Api.request.ROOT_API_URL}/SION/images/profiles/${user.id}.jpg`;
    const widgets = this.props.widgets;
    const data = (notifications && notifications.slice(0, page * 10)) || [];
    const hasMore = !notifications ? false : data.length < notifications.length;
    const canAccess = access && access.find((e) => e.name === `Dashboard`);
    return (
      <div>
        <Dialog
          ref={(el) => {
            this.dialog = el;
          }}
        />
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
          <Helmet onChangeClientState={this.handleHelmetChange} />
          <div className="navbar-brand-wrapper d-flex justify-content-center">
            <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
              <Link to={`/`} className="navbar-brand brand-logo">
                <img src="/images/DE_expanded_logo@2x.png" alt="DE" />
              </Link>
              <Link to={`/`} className="navbar-brand brand-logo-mini">
                <img src="/images/DE_collapsed_logo@2x.png" alt="DE" />
              </Link>
            </div>
          </div>
          <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
            <ul className="navbar-nav mr-lg-2">
              <li className="nav-item d-none d-lg-block">
                <h4 className="mb-0">{pageTitle}</h4>
              </li>
            </ul>
            <ul className="navbar-nav navbar-nav-right">
              {canAccess && (
                <Dropdown
                  tag="li"
                  isOpen={notificationOpen}
                  toggle={this.toggleNotification}
                  className="nav-item"
                >
                  <DropdownToggle
                    style={{ minWidth: "unset" }}
                    className="nav-link count-indicator dropdown-toggle d-flex justify-content-center align-items-center"
                    id="notificationDropdown"
                    href="#"
                    data-toggle="dropdown"
                    onClick={this.toggleNotification}
                  >
                    {!data && <i className="mdi mdi-bell mx-0" />}
                    {data && <i className="mdi mdi-bell-ring mx-0" />}
                    {/* <span className="count" /> */}
                  </DropdownToggle>
                  <DropdownMenu
                    right
                    className="navbar-dropdown preview-list notifications-list"
                    aria-labelledby="notificationDropdown"
                  >
                    <DropdownItem header>Notifications</DropdownItem>
                    <InfiniteScroll
                      loadMore={this.loadMore}
                      hasMore={hasMore}
                      useWindow={false}
                      loader={
                        <div className="dropdown-item" key={0}>
                          Loading ...
                        </div>
                      }
                    >
                      {data &&
                        data.map((d) => {
                          return (
                            <DropdownItem
                              className="preview-item notification-item"
                              key={d.id}
                            >
                              <div className="preview-thumbnail">
                                <div className="preview-icon">
                                  <i
                                    className="mdi mdi-information-outline mx-0"
                                    style={{ color: "var(--primary-blue)" }}
                                  />
                                </div>
                              </div>
                              <div className="preview-item-content">
                                <p className="preview-subject font-weight-normal font-size-small">
                                  {displayNotification(d)}
                                </p>
                                <p className="font-weight-light small text-muted mb-0">
                                  {moment(d.created).fromNow()}
                                </p>
                              </div>
                            </DropdownItem>
                          );
                        })}
                    </InfiniteScroll>
                  </DropdownMenu>
                </Dropdown>
              )}
              <Dropdown
                tag="li"
                isOpen={settingOpen}
                toggle={this.toggleSetting}
                className="nav-item nav-profile mr-0 mr-sm-3"
              >
                <DropdownToggle
                  onClick={this.toggleSetting}
                  className="nav-link dropdown-toggle"
                  id="profileDropdown"
                  tag="a"
                >
                  <img
                    src={defaultImage} //once fixed change it to URL from image variable above
                    // onError={loadImageError}
                    className="nav-profile"
                    alt="Source not found"
                  ></img>
                  <span className="nav-profile-name mr-2">
                    {firstname} {lastname}
                  </span>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu dropdown-menu-right navbar-dropdown"
                  aria-labelledby="profileDropdown"
                >
                  {/* <DropdownItem>
                    <Link
                      to={`/settings`}
                      style={{ color: "var(--primary-text-color)" }}
                    >
                      <i className="mdi mdi-settings text-primary" />
                      Settings
                    </Link>
                  </DropdownItem> */}

                  <DarkToggle />

                  {/* <DropdownItem>
                    <Link
                      to={`/accessreview/reviews`}
                      style={{ color: "var(--primary-text-color)" }}
                    >
                      <i className="mdi mdi-account-check text-primary" />
                      My Access Reviews
                    </Link>
                  </DropdownItem> */}
                  <DropdownItem onClick={this.setLayout}>
                    <i className="mdi mdi-content-save-all-outline text-primary" />
                    Save Widgets
                  </DropdownItem>
                  <DropdownItem onClick={this.resetLayout}>
                    <i className="mdi mdi-lock-reset text-primary" />
                    Reset Widgets
                  </DropdownItem>
                  {/* <DropdownItem
                onClick={this.showWidgetList}>
                   Update Widgets
                </DropdownItem> */}
                  {sroleid === 1 && (
                    <DropdownItem onClick={this.showAccessModal}>
                      <i className="mdi mdi-account-box-multiple text-primary" />
                      Switch Organization
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="dropdown-item"
                    onClick={(e) => this.props.doLogout()}
                  >
                    <i className="mdi mdi-laptop-off text-primary" />
                    Logout
                    {/* <Modal open={open} onClose={this.props.doLogout} center={true} >
                <h2 style={{'text-align':'center'}}>Signed Out!</h2>
                <p>You have successfully logged out of your SiON account!</p>
                </Modal> */}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </ul>
            {this.state.showModal && (
              <WidgetUpdate onClose={this.onClose} widgets={widgets} />
            )}
            {this.state.accessModal && (
              <SwitchOrganization
                onClose={this.onClose}
                user={user}
                orgs={this.state.authorizedOrgs}
              />
            )}
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              onClick={this.props.onToggleSidebar}
            >
              <span className="mdi mdi-menu text-primary" />
            </button>
          </div>
        </nav>
      </div>
    );
  }
}
// export default Navbar;
const mapStateToProps = (store) => {
  const { user, notifications, widgets } = store.get("auth").toJS();
  return {
    user: user || { firstname: "", lastname: "", access: [] },
    notifications,
    widgets,
  };
};
const mapDispatchToProps = (dispatch) => ({
  doLogout: () => dispatch(doLogout()),
  doRenew: () => dispatch(doRenew()),
  loadNotifications: () => dispatch(loadNotifications()),
  saveLayout: (e) => dispatch(saveLayout(e)),
  getDashboardData: () => dispatch(getDashboardData()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
