import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { compose } from "redux";
import "../../Assets/scss/index.scss";
import injectSaga from "../../core/store/injectSaga";
import Loader from "../Loader";
import Router from "./Router";
import saga from "./saga";
import ScrollToTop from "./ScrollToTop";
class App extends Component {
  render() {
    return (
      <ScrollToTop>
        <Loader />
        <Helmet>
          <title>SION</title>
          <meta name="description" content="SION Application" />
        </Helmet>
        <Router />
        <ToastContainer timeout={2500} />
        {/* <Feedback/> */}
      </ScrollToTop>
    );
  }
}

// export default App;
const withSaga = injectSaga({ key: "rootSaga", saga });

export default compose(withSaga)(App);
