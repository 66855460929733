import React, { Component } from "react";
import { toast } from "react-toastify";
import Api from "../../core/Api";
import "./index.scss";
import ModalForm from "./ModalForm";

class Feedback extends Component {
  state = {
    isOpen: false,
  };

  toggleOpen = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };
  onCancel = (e) => {
    this.setState({ isOpen: false });
  };
  onSubmit = (data) => {
    this.setState({ isOpen: false });
    Api.callapi
      .sendFeedback(data)
      .then((resp) => {
        toast("Thank you for submitting feedback.", { type: "success" });
      })
      .catch((err) => {
        toast("Could not send feedback. Please check your network!", {
          type: "error",
        });
      });
  };
  render() {
    const { user } = this.props;
    const email = user && user.email ? user.email : "";
    return (
      <div className="feedback">
        {!this.state.isOpen && (
          <button
            type="button"
            className="btn btn-outline-primary btn-lg btn-rounded btn-float btn-feedback"
            onClick={this.toggleOpen}
          >
            <i className="fa fa-comment-o" aria-hidden="true"></i> Feedback
          </button>
        )}
        <ModalForm
          isOpen={this.state.isOpen}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          fromemail={email}
        />
      </div>
    );
  }
}

export default Feedback;
