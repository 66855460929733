import React from "react";
import Select, { components } from "react-select";

type PagesizeSelectType = {
  pageSize: number;
  pageSizes: {
    value: number
    label: string
  }[];
  onSelect: () => void;
};

/**
 * Dropdown selection for showing a selection of items.
 * Used to display 10, 25, 50, or 100 items in a list.
 */
export default class PagesizeSelect extends React.Component<PagesizeSelectType> {
  static defaultProps = {
    pageSizes: [
      { value: 10, label: "10" },
      { value: 25, label: "25" },
      { value: 50, label: "50" },
      { value: 100, label: "100" },
    ],
  };

  render() {
    const { pageSize, pageSizes, onSelect } = this.props;
    const DropdownIndicator = (props: JSX.IntrinsicAttributes) => (
      <components.DropdownIndicator {...props}>
        <div
          style={{
            marginRight: 4,
          }}
        >
          <span style={{ color: "#d6d6d6" }}>▾</span>
        </div>
      </components.DropdownIndicator>
    );
    return (
      <Select
        placeholder={pageSize}
        name="pageSize"
        className="select-pageSize"
        components={{ DropdownIndicator }}
        id="valShow"
        menuPlacement="auto"
        styles={{
          option: (provided: any, state: { isSelected: any }) => ({
            ...provided,
            borderBottom: "1px var(--border)",
            color: state.isSelected ? "#fff" : "var(--primary-text-color)",
            cursor: "pointer",
            background: state.isSelected
              ? "var(--primary-blue)"
              : "var(--card-bg)",
            "&:hover": {
              color: "#fff",
              background: state.isSelected
                ? "var(--primary-blue)"
                : "var(--hover)",
            },
          }),
          indicatorSeparator: () => ({
            color: "rgba(0,0,0,0)",
          }),
          dropdownIndicator: () => ({
            backgroundColor: "rgba(0,0,0,0)",
          }),
          control: () => ({
            display: "flex",
            borderRadius: 6,
            cursor: "pointer",
          }),
          menu: (provided: any) => ({
            ...provided,
            backgroundColor: "var(--card-bg)",
            zIndex: 9999,
          }),
          placeholder: (defaultStyles: any) => {
            return {
              ...defaultStyles,
              color: "var(--primary-text-color)",
            };
          },
        }}
        onChange={onSelect}
        options={pageSizes}
        value={pageSize}
      />
    );
  }
}
