import React, { Component } from "react";
import { Modal } from "reactstrap";

class ModalForm extends Component {
  state = {
    type: "Idea",
    summary: "",
    description: "",
    fromemail: "",
  };
  static getDerivedStateFromProps(props, state) {
    if (!props.fromemail) {
      return false;
    }

    return {
      fromemail: props.fromemail,
    };
  }
  onChangeType = (e, tp) => {
    e.preventDefault();
    this.setState({ type: tp });
  };
  handleInputChange = (e) => {
    e.preventDefault();
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  onCancel = (e) => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(true);
    }
  };
  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { type, summary, description, fromemail } = this.state;
    if (onSubmit) {
      onSubmit({
        type,
        summary,
        description,
        fromemail,
      });
    }
  };
  render() {
    const listTypes = [
      { name: "Idea", className: "fa fa-lightbulb-o" },
      { name: "Issue", className: "fa fa-exclamation-triangle" },
      { name: "Question", className: "fa fa-question-circle" },
      { name: "Praise", className: "fa fa-heart" },
    ];

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentClassName="card-feedback my-5 mx-auto feedback-form"
      >
        <div className="card-header">
          <strong>We'd Love Your Feedback</strong>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onCancel}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={this.onSubmit}>
          <div className="card-body">
            <div
              className="btn-group btn-group-toggle d-flex mb-3"
              data-toggle="buttons"
            >
              {listTypes.map((tt) => (
                <label
                  key={tt.name}
                  className={`btn btn-secondary btn-arrow ${
                    tt.name === this.state.type ? "active" : ""
                  }`}
                  onClick={(e) => this.onChangeType(e, tt.name)}
                >
                  <input
                    type="radio"
                    name="options"
                    value={tt.name}
                    defaultChecked={tt.name === this.state.type}
                  />
                  <i className={tt.className} aria-hidden="true"></i> {tt.name}
                </label>
              ))}
            </div>
            <div className="form-group">
              <label htmlFor="title" className="sr-only">
                Title
              </label>
              <input
                type="text"
                name="summary"
                className="form-control"
                id="title"
                placeholder="Title"
                onChange={this.handleInputChange}
                value={this.state.title}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description" className="sr-only">
                Your feedback
              </label>
              <textarea
                name="description"
                className="form-control"
                id="description"
                rows="3"
                placeholder="Your feedback"
                onChange={this.handleInputChange}
                value={this.state.description}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <label htmlFor="fromemail" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="fromemail"
                className="form-control"
                id="fromemail"
                placeholder="Enter email"
                onChange={this.handleInputChange}
                value={this.state.fromemail}
                required
              />
            </div>
          </div>
          <div className="card-footer d-flex justify-content-center">
            <button type="submit" className="btn btn-primary">
              Submit Feedback
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalForm;
