// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: {
    path: "/login",
    exact: true,
  },
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    details: {
      path: "/dashboard/details/alerts",
      exact: true,
      name: "DashboardDetails",
    },
    acknowledged: {
      path: "/dashboard/details/acknowledged",
      exact: true,
      name: "Acknowledged",
    },
    ticketed: {
      path: "/dashboard/details/ticketed",
      exact: true,
      name: "Ticketed",
    },
    uploadPcap: {
      path: "/dashboard/packetcapture/uploadPcap",
      exact: true,
      name: "UploadPcap",
    },
    pcapTraffic: {
      path: "/dashboard/packetcapture/traffic",
      exact: true,
      name: "pcapTraffic",
    },
    pcapAnalysis: {
      path: "/dashboard/packetcapture/analysis",
      exact: true,
      name: "pcapAnalysis",
    },
    blocked: {
      path: "/dashboard/details/blocked",
      exact: true,
      name: "blocked",
    },
    anomalies: {
      path: "/dashboard/details/anomalies",
      exact: true,
      name: "Anomalies",
    },
    anomalyTicketed: {
      path: "/dashboard/details/anomaliesticketed",
      exact: true,
      name: "Ticketed",
    },
    anomalyAcknowledged: {
      path: "/dashboard/details/anomalyAcknowledged",
      exact: true,
      name: "Acknowledged",
    },
    knowndeviceDetails: {
      path: "/dashboard/devicedetails/knowndevices",
      exact: true,
      name: "DashboardDetailKnown",
    },
    unknowndeviceDetails: {
      path: "/dashboard/devicedetails/unknowndevices",
      exact: true,
      name: "DashboardDetailUnknown",
    },
    archivedDevices: {
      path: "/dashboard/devicedetails/archiveddevices",
      exact: true,
      name: "ArchivedDevices",
    },
    discoveredDevices: {
      path: "/dashboard/devicedetails/discovereddevice",
      exact: true,
      name: "ArchivedDevices",
    },
    uploadDevices: {
      path: "/dashboard/devicedetails/uploaddevices",
      exact: true,
      name: "UploadDevices",
    },
    switchDevices: {
      path: "/dashboard/devicedetails/switchdevices",
      exact: true,
      name: "SwitchDevices",
    },
    threatMap: {
      path: "/dashboard/threatmap",
      exact: false,
      name: "ThreatMap",
    },
    lastLogOn: {
      path: "/dashboard/lastLogInInfo",
      exact: false,
      name: "LastLog",
    },
    AccountLockout: {
      path: "/dashboard/accountlockout",
      exact: false,
      name: "AccountLockout",
    },
    TermUser: {
      path: "/dashboard/terminatedusers",
      exact: false,
      name: "TerminatedUser",
    },
    FailedLogin: {
      path: "/dashboard/failedattemptinfo",
      exact: false,
      name: "FailedLogin",
    },
    OrgRiskScore: {
      path: "/dashboard/riskscore",
      exact: false,
      name: "RiskScore",
    },
    ThreatMap: {
      path: "/dashboard/threatmap",
      exact: false,
      name: "ThreatMap",
    },
    OneLoginUsers: {
      path: "/dashboard/oneloginusers",
      exact: false,
      name: "OneLoginUsers",
    },
    OneLoginGroups: {
      path: "/dashboard/onelogingroups",
      exact: false,
      name: "OneLoginGroups",
    },
    AzureLogin: {
      path: "/dashboard/azurelogin",
      exact: false,
      name: "AzureLogin",
    },
    ServiceAccounts: {
      path: "/dashboard/serviceaccounts",
      exact: false,
      name: "ServiceAccounts",
    },
    MFADisabled: {
      path: "/dashboard/mfadisabled",
      exact: false,
      name: "MFADisabled",
    },
    MFAEnabled: {
      path: "/dashboard/mfaenabled",
      exact: false,
      name: "MFAEnabled",
    },
    MLWidget: {
      path: "/dashboard/mlscript",
      exact: false,
      name: "MLWidget",
    },
    AdminLogins: {
      path: "/dashboard/adminlogin",
      exact: false,
      name: "AdminLogins",
    },
  },
  analytics: {
    path: "/analytics",
    name: "Analytics",
    exact: true,
  },
  timelines: {
    path: "/timelines",
    name: "Timelines",
    exact: true,
  },
  applications: {
    path: "/applications",
    name: "Applications",
    exact: false,
    applicationsUpdate: {
      path: "/applications/:id",
      name: "Applications",
      exact: true,
    },
  },
  management: {
    path: "/management",
    name: "Management",
    exact: true,
    children: [
      {
        path: "/:id",
        name: "EmployeeDetails",
      },
    ],
  },
  roles: {
    path: "/roles",
    name: "Roles",
    exact: true,
  },
  employee: {
    path: "/management/:id",
    name: "Employee",
    exact: true,
  },
  settings: {
    path: "/settings",
    name: "Settings",
    exact: false,
  },
  access: {
    path: "/accessreview",
    name: "ReviewPage",
    exact: false,
    reviewList: {
      path: "/accessreview",
      name: "ReviewPage",
      exact: false,
    },
  },
  report: {
    path: "/reports",
    name: "ReportsPage",
    exact: true,
  },
};
