import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import logo from "../../Assets/images/auth/logo.png";
// import injectReducer from '../../utils/injectReducer';
import injectSaga from "../../core/store/injectSaga";
import { doLogin } from "../App/actions";
// import reducer from './reducer';
import saga from "./saga";

class LoginPage extends Component {
  state = {};
  componentDidMount() {}
  onLogin = (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { username, secret } = this.state;
    // {username: 'Calibrate', secret: 'Security!2019'}
    this.props.requestLogin({ username, secret }, (success) => {
      this.setState({ submitting: false });
      if (!success) {
        this.setState({ errorMessage: "Username or password is invalid" });
      }
    });
  };
  handleChangeInput = (e) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div className="row flex-grow">
              <div className="col-lg-6 login-half-bg">
                <div className="login-half-text">
                  <img src={logo} alt="Source not found" />
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div className="auth-form-transparent text-center p-3">
                  <h4>
                    Welcome to <img src={logo} alt="Source not found" />{" "}
                  </h4>
                  {/* <h6 className="font-weight-light">
                    Login to your SION account
                  </h6> */}
                  <form className="pt-3 text-left">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail">Username</label>
                      <div className="input-group">
                        <input
                          name="username"
                          type="text"
                          className="form-control form-control-lg border-left-0"
                          id="exampleInputEmail"
                          placeholder="Enter Your Username"
                          onChange={this.handleChangeInput}
                        />
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="mdi mdi-account-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword">Password</label>
                      <div className="input-group">
                        <input
                          type="password"
                          name="secret"
                          className="form-control form-control-lg border-left-0"
                          id="exampleInputPassword"
                          placeholder="Enter Your Password"
                          onChange={this.handleChangeInput}
                        />
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="mdi mdi-lock-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    {this.state.errorMessage && (
                      <div className="text-danger text-center">
                        <br />
                        {this.state.errorMessage}
                      </div>
                    )}
                    <div className="my-2 d-flex align-items-center justify-content-center mt-5">
                      <button
                        className="btn btn-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={this.onLogin}
                        disabled={this.state.submitting}
                      >
                        LOGIN
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({
  requestLogin: (evt, cb) => dispatch(doLogin(evt, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

// const withReducer = injectReducer({ key: 'auth', reducer });
const withSaga = injectSaga({ key: "auth", saga });

export default compose(
  // withReducer,
  withSaga,
  withConnect
)(LoginPage);
