import { Route, Redirect, withRouter } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import Request from "../../core/Api/request";
import { doLogout } from "../../containers/App/actions";

const PrivateRoute = ({
  component: Component,
  token,
  dispatch,
  history,
  ...rest
}) => {
  if (token)
    Request.setToken(token, () => {
      dispatch(doLogout());
    });

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};

function mapStateToProps(store) {
  return {
    token: store.getIn(["auth", "token"]),
  };
}

export default connect(mapStateToProps, null)(withRouter(PrivateRoute));
