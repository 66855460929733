import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { loadUser, toggleSidebar } from "../App/actions";
import Feedback from "../Feedback";
import { endConnect } from "../Loader/actions";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";

const getLocationKey = (location) => {
  return `${location.pathname}?${location.search}${location.hash}`;
};
class Layout extends Component {
  layoutRef = React.createRef();
  state = {
    sidebar: true,
    grid: [],
    layout: [],
  };
  componentDidMount() {
    document.addEventListener("DOMContentLoaded", this.resizeHandler, true);
    window.addEventListener("resize", this.resizeHandler, true);
    this.fetchCurrentUser();
    this.locationKey = getLocationKey(this.props.location);
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.locationKey !== getLocationKey(location)) {
        this.props.dispatch(endConnect());
        this.locationKey = getLocationKey(location);
      }
    });
  }
  componentWillUnmount() {
    document.removeEventListener("DOMContentLoaded", this.resizeHandler, true);
    window.removeEventListener("resize", this.resizeHandler, true);
    this.unlisten();
  }

  setLayoutData = (data, grid) => {
    this.setState({ layout: data });
    this.setState({ grid: grid });
  };
  resizeHandler = () => {
    if (window.innerWidth <= 991) {
      this.setState({ sidebar: false });
    } else {
      this.setState({ sidebar: true });
    }
  };

  fetchCurrentUser = () => {
    if (this.props.token && !this.props.user) {
      this.props.loadUser();
    }
  };
  toggleSidebar = () => {
    const { sidebar } = this.props;
    this.props.toggleSidebar(!sidebar);
  };
  render() {
    // const { sidebar } = this.state;
    const { user, sidebar } = this.props;
    const classNames = sidebar
      ? "container-scroller"
      : "container-scroller sidebar-icon-only";
    // if (!user) return null;
    const layoutProps = React.Children.map(this.props.children, (child) => {
      var setLayoutData = this.setLayoutData;
      const setLayout = { setLayoutData };
      if (React.isValidElement(child)) {
        return React.cloneElement(child, setLayout);
      }
      return child;
    });
    return (
      <div className={classNames}>
        <Navbar
          onToggleSidebar={this.toggleSidebar}
          layout={this.state.layout}
          grid={this.state.grid}
        />
        <div className="container-fluid page-body-wrapper">
          <Sidebar
            sidebar={sidebar}
            toggleSidebar={this.toggleSidebar}
            user={user}
          />
          <div className="main-panel">
            <div className="content-wrapper">{layoutProps}</div>
          </div>
        </div>
        <Feedback user={user} />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { token, user, sidebar } = store.get("auth").toJS();
  return {
    token,
    user,
    sidebar,
  };
};
const mapDispatchToProps = (dispatch) => ({
  loadUser: () => dispatch(loadUser()),
  toggleSidebar: (shown) => dispatch(toggleSidebar(shown)),
  dispatch,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(Layout);
