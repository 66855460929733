import React, { Component } from "react";
import { Modal } from "reactstrap";

import Api from "../../../core/Api";

import { toast } from "react-toastify";

class RequestAccess extends Component {
  state = {
    type: "Idea",
    summary: "",
    description: "",
    fromemail: "",
  };
  static getDerivedStateFromProps(props, state) {
    if (!props.fromemail) {
      return false;
    }

    return {
      fromemail: props.fromemail,
    };
  }
  onChangeType = (e, tp) => {
    e.preventDefault();
    this.setState({ type: tp });
  };
  handleInputChange = (e) => {
    e.preventDefault();
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    const { description, fromemail } = this.state;
    Api.callapi
      .requestOrg(fromemail, description)
      .then((resp) => {
        toast("Thank you for submitting request.", { type: "success" });
      })
      .catch((err) => {
        toast("Could not send request. Please check your network!", {
          type: "error",
        });
      });
    this.props.onClose();
    // axios
    //   .post(
    //     `${Api.request.ROOT_API_URL}/requestorg/`,
    //     JSON.stringify({
    //       requestor: fromemail,
    //       message: description,
    //     }),
    //     {
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    //         Authorization: `Bearer ${persist}`,
    //       },
    //     }
    //   )
    //   .then((success) => {
    //     this.props.onClose();
    //   });
  };
  render() {
    const { onClose } = this.props;

    return (
      <Modal isOpen={true} contentClassName="card-feedback my-5 mx-auto">
        <div className="modal-header">
          <strong>Request Access for an Organization</strong>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <h5
              htmlFor="recipient-name"
              className="col-form-label text-primary"
            >
              Your Reason
            </h5>
            <textarea
              name="description"
              className="form-control"
              id="description"
              rows="3"
              placeholder="Organization & Reason"
              onChange={this.handleInputChange}
              value={this.state.description}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="fromemail" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="fromemail"
              className="form-control"
              id="fromemail"
              placeholder="Enter email"
              // onChange={this.handleInputChange}
              value={this.state.fromemail}
              disabled
              required
            />
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <button className="btn btn-primary" onClick={this.onSubmit}>
            Submit Request
          </button>
        </div>
      </Modal>
    );
  }
}

export default RequestAccess;
