/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router/immutable";
import "@kenshooui/react-multi-select/dist/style.css";
// import FontFaceObserver from 'fontfaceobserver';
import history from "./core/utils/history";
import "./index.css";
import "./Assets/css/custom.scss";

import "@babel/polyfill";

// Import root app
import App from "./containers/App";

import configureStore from "./core/store/configureStore";

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);

const MOUNT_NODE = document.getElementById("root");

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   // module.hot.accept(['./containers/App'], () => {
//   //   ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//   // });
// }

render();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
  require("offline-plugin/runtime").install(); // eslint-disable-line global-require
}
