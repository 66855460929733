import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import RouteName from "../../App/RouteNames";

class Sidebar extends Component {
  checkActiveRoute = (path, exact = false) => {
    const {
      history: { location },
    } = this.props;

    return location.pathname.match(
      new RegExp(`^${path}${exact ? "$" : ""}`, "i")
    )
      ? "nav-item active"
      : "nav-item";
  };

  render() {
    const { sidebar, user } = this.props;

    const classNames = sidebar
      ? "sidebar sidebar-offcanvas active"
      : "sidebar sidebar-offcanvas";
    const canAccess = (permission) => {
      return (
        user && user.access && user.access.find((e) => e.name === permission)
      );
    };
    return (
      <nav className={classNames} id="sidebar">
        <ul className="nav">
          {canAccess("Dashboard") && (
            <li className={this.checkActiveRoute(RouteName.dashboard.path)}>
              <Link
                className="nav-link"
                to={RouteName.dashboard.path}
                title="Dashboard"
              >
                <i className="mdi mdi-view-dashboard menu-icon" />
                <span className="menu-title">Dashboard</span>
              </Link>
            </li>
          )}
          {canAccess("Analytics") && (
            <li
              className={this.checkActiveRoute(RouteName.timelines.path, true)}
            >
              <Link
                className="nav-link"
                to={RouteName.timelines.path}
                title="Timeline"
              >
                <i className="mdi mdi-chart-bar menu-icon" />
                <span className="menu-title">Events</span>
              </Link>
            </li>
          )}
          {canAccess("Applications") && (
            <li className={this.checkActiveRoute(RouteName.applications.path)}>
              <Link
                className="nav-link"
                to={RouteName.applications.path}
                title="Applications"
              >
                <span className="mdi mdi-applica menu-icon">
                  <span />
                  <span />
                  <span />
                  <span />
                </span>
                <span className="menu-title">Connectors</span>
              </Link>
            </li>
          )}
          {canAccess("User Management") && (
            <li className={this.checkActiveRoute(RouteName.management.path)}>
              <Link
                className="nav-link"
                to={RouteName.management.path}
                title="Management"
              >
                <i className="mdi mdi-account-group menu-icon" />
                <span className="menu-title">Management</span>
              </Link>
            </li>
          )}
          {canAccess("Access Review") && (
            <li className={this.checkActiveRoute(RouteName.access.path)}>
              <Link className="nav-link" to={RouteName.access.path}>
                <i className="mdi mdi-account-check menu-icon" />
                <span className="menu-title">Access</span>
              </Link>
            </li>
          )}
          {canAccess("Reports") && (
            <li className={this.checkActiveRoute(RouteName.report.path)}>
              <Link className="nav-link" to={RouteName.report.path}>
                <i className="mdi mdi-file-document menu-icon" />
                <span className="menu-title">Reports</span>
              </Link>
            </li>
          )}
          {canAccess("User Roles") && (
            <li className={this.checkActiveRoute(RouteName.roles.path)}>
              <Link className="nav-link" to={RouteName.roles.path}>
                <i className="mdi mdi-account-badge-horizontal menu-icon" />
                <span className="menu-title">Roles</span>
              </Link>
            </li>
          )}
          {/*  {canAccess("Settings") && (
            <li className={this.checkActiveRoute(RouteName.settings.path)}>
              <Link className="nav-link" to={RouteName.settings.path}>
                <i className="mdi mdi-settings menu-icon" />
                <span className="menu-title">Settings</span>
              </Link>
            </li>
          )} */}
        </ul>
        <br></br>

        <button
          className="navbar-editor navbar-toggler text-center mt-5"
          type="button"
          onClick={this.props.toggleSidebar}
          title={sidebar ? "HIDE" : "SHOW"}
        >
          <div className="row">
            <span
              className={
                sidebar
                  ? "mdi mdi-arrow-collapse-left col-md-4"
                  : "mdi mdi-arrow-collapse-right col-md-4 ml-5"
              }
            />{" "}
            <small className="col-md-8 pt-1">{sidebar ? "HIDE" : "SHOW"}</small>
          </div>
        </button>
        <span
          className={`navbar-editor2 text-center ${sidebar ? "" : "m-auto"}`}
        >
          v4.6
        </span>
      </nav>
    );
  }
}

export default withRouter(Sidebar);
