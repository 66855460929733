import React, { Component } from "react";

class DarkToggle extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
    this.state = {
      checked: false,
    };
  }

  clickHandler(e) {
    this.setState({
      checked: e.target.checked,
    });

    document.body.classList.toggle("dark-theme");

    if (document.body.classList.contains("dark-theme")) {
      localStorage.setItem("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
    }
  }

  render() {
    return (
      <button
        className="dropdown-item"
        type="button"
        onClick={this.clickHandler}
        checked={this.state.checked}
      >
        <i className="mdi mdi-theme-light-dark text-primary" />
        {document.body.classList.contains("dark-theme")
          ? "Enable Light Mode"
          : "Enable Dark Mode"}
      </button>
    );
  }
}
export default DarkToggle;
