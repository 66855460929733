import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import Api from "../../core/Api";
import {
  LOAD_USER_REQUEST,
  LOAD_USER_REQUEST_SUCCESS,
  LOAD_USER_REQUEST_FAILURE,
  RENEW_REQUEST,
  RENEW_SUCCESS,
  RENEW_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_FAILURE,
  LAYOUT_FAILURE,
  LAYOUT_REQUEST,
} from "./constants";

function* loadUserSaga(action) {
  const { callback } = action;
  try {
    const res = yield call(Api.callapi.getUser);
    // const resp = yield call(Api.callapi.getWidgets)
    // yield put({ type: WIDGET_REQUEST_SUCCESS, payload: resp.data });
    yield put({ type: LOAD_USER_REQUEST_SUCCESS, payload: res.data });
    callback(true);
  } catch (err) {
    callback(false);
    yield put({ type: LOAD_USER_REQUEST_FAILURE });
  }
}

function* renewSaga(action) {
  const { callback } = action;
  try {
    yield call(Api.callapi.renewToken);
    yield put({
      type: RENEW_SUCCESS,
      payload: { access_token: new Date().getTime() },
    });

    callback(true);
  } catch (err) {
    callback(false);
    yield put({ type: RENEW_FAILURE });
  }
}

function* logoutSaga(action) {
  const { callback } = action;
  try {
    yield put(push("/login"));
    yield call(Api.callapi.logout);
    yield put({ type: LOGOUT_SUCCESS, payload: {} });

    callback(true);
  } catch (err) {
    callback(false);
    yield put({ type: LOGOUT_FAILURE });
  }
}

function* notificationsSaga(action) {
  const { callback } = action;
  try {
    const res = yield call(Api.callapi.getDetailStats);

    yield put({ type: GET_NOTIFICATIONS_REQUEST_SUCCESS, payload: res.data });

    callback(true);
  } catch (err) {
    callback(false);
    yield put({ type: GET_NOTIFICATIONS_REQUEST_FAILURE });
  }
}

function* layoutSaveSaga({ payload }) {
  try {
    const user = yield call(Api.callapi.getUser);
    yield call(Api.callapi.saveLayout, payload, user.data.name);
  } catch (err) {
    console.log(err);
    yield put({ type: LAYOUT_FAILURE });
  }
}

export default function* appWatcher() {
  yield takeLatest(LOAD_USER_REQUEST, loadUserSaga);
  yield takeLatest(LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(RENEW_REQUEST, renewSaga);
  yield takeLatest(LAYOUT_REQUEST, layoutSaveSaga);
  yield takeLatest(GET_NOTIFICATIONS_REQUEST, notificationsSaga);
}
