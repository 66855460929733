import axios from "axios";
import { getData } from "../utils/localStorage";
const ROOT_API_URL =
  process.env.REACT_APP_REST_API_API || "https://sion.defendedge.io";

const constructUrlEndPoint = (api) => `${ROOT_API_URL}/${api}`;
const REPORT_URL = `${ROOT_API_URL}/SION/pages/stats/getBinaryData.jsp?type=report/`;
//axios.defaults.withCredentials = true;

const parseJSON = (text) => {
  if (!text) return text;
  if (typeof text !== "string") return text;
  if (text.indexOf("[") < 0 && text.indexOf("{") < 0) return text;
  let idx = Math.max(0, text.indexOf("{"));
  idx = text.indexOf("[") > 0 ? Math.min(idx, text.indexOf("[")) : idx;
  return JSON.parse(text.slice(idx, text.length));
};

const formatStringUrl = (...args) => {
  let i = 1;
  const str = args[0];
  return str.replace(/\{\}/g, () => args[i++]); // eslint-disable-line
};

const prettifyEndpoint = (api, ...args) =>
  formatStringUrl(constructUrlEndPoint(api), ...args);

const processFormData = (objectKey, fileKey, data) => {
  if (!data[fileKey]) {
    return { [objectKey]: data };
  }
  const form = new FormData();
  Object.keys(data).map((key) =>
    form.append([`${objectKey}[${key}]`], data[key])
  );
  return form;
};

let accessToken = null;

const clearToken = () => {
  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.withCredentials = false;
  accessToken = null;
  if (window && window.location) {
    window.location.reload();
  }
};

const setToken = (token, onInvalidToken) => {
  accessToken = token;
  // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.withCredentials = true;

  const date = new Date(accessToken);
  date.setMinutes(date.getMinutes() + 300); //autolog off after 5 hrs, should disable this since now its done in Navbar
  axios.interceptors.response.use(
    (response) => {
      if (date < new Date()) {
        if (onInvalidToken) {
          onInvalidToken({ error: "expired session" });
        }
      }

      return Promise.resolve({ ...response, data: parseJSON(response.data) });
    },
    (err) => {
      if (date < new Date() || (err.response && err.response.status === 401)) {
        accessToken = null;
        if (onInvalidToken) {
          onInvalidToken(err);
        }
      }
      return Promise.reject(err);
    }
  );
};

const getToken = () => {
  return accessToken;
};

const getReq = (url, params = {}) => {
  const persist = getData("access_token");
  // const encodedToken = window.btoa(persist);
  try {
    return axios.get(prettifyEndpoint(url), {
      headers: {
        Authorization: `Bearer ${persist}`,
      },
    });
  } catch (error) {
    //check for error and logout if user session is terminated
    console.log(error);
  }
};

const postReq = (url, params, config) => {
  const persist = getData("access_token");
  try {
    return axios.post(prettifyEndpoint(url), params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        Authorization: `Bearer ${persist}`,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

const putReq = (url, params, dataKeys = null) => {
  if (!dataKeys || dataKeys.length === 0) {
    return axios.put(prettifyEndpoint(url), { ...params });
  } else {
    return axios.put(
      prettifyEndpoint(url),
      processFormData(dataKeys[0], dataKeys[1], params)
    );
  }
};

const deleteReq = (url, data) => {
  return axios.put(prettifyEndpoint(url), { data });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: getReq,
  post: postReq,
  put: putReq,
  delete: deleteReq,
  ROOT_API_URL,
  setToken,
  getToken,
  clearToken,
  REPORT_URL,
};
