export const loadState = () => {
  return getData("persistState") || {};
};
const whiteList = ["token", "sidebar"];
export const saveState = (state) => {
  try {
    const dupState = {};
    whiteList.map((key) => (dupState[key] = state[key]));
    saveData("persistState", dupState);
  } catch (err) {
    console.log("saveState error", err);
  }
};

export const getData = (key) => {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData === null) {
      return null;
    }
    return JSON.parse(serializedData);
  } catch (err) {
    return undefined;
  }
};

export const saveData = (key, data) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (err) {
    console.log("saveState error", err);
  }
};
