import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import RouteNames from "./RouteNames";

const Dashboard = React.lazy(() => import("../Dashboard"));
const ManagementPage = React.lazy(() => import("../Management"));

const ReviewPage = React.lazy(() => import("../Access"));
const ApplicationPage = React.lazy(() => import("../Application"));
const AnalyticsPage = React.lazy(() => import("../Analytics"));
const EmployeePage = React.lazy(() => import("../Management/Employee"));
const SettingPage = React.lazy(() => import("../Settings"));
const TimelinesPage = React.lazy(() => import("../Timelines"));
const ReportsPage = React.lazy(() => import("../Reports"));
const RolesPage = React.lazy(() => import("../Role_Logins"));

const allRouters = [
  // {
  //     path: RouteNames.dashboard.path,
  //     component: Dashboard,
  // },
  {
    path: RouteNames.applications.path,
    component: ApplicationPage,
    permission: "Applications",
  },
  {
    exact: true,
    path: RouteNames.analytics.path,
    component: AnalyticsPage,
    permission: "Analytics",
  },
  {
    exact: true,
    path: RouteNames.management.path,
    component: ManagementPage,
    permission: "User Management",
  },
  {
    path: RouteNames.employee.path,
    component: EmployeePage,
    permission: "User Management",
  },
  {
    path: RouteNames.settings.path,
    component: SettingPage,
    permission: "Settings",
  },
  {
    path: RouteNames.access.path,
    component: ReviewPage,
    permission: "Access Review",
  },
  {
    path: RouteNames.roles.path,
    component: RolesPage,
    permission: "User Roles",
  },
  {
    path: RouteNames.report.path,
    component: ReportsPage,
    permission: "Reports",
  },
  {
    path: RouteNames.timelines.path,
    component: TimelinesPage,
    permission: "Settings",
  },
];

const SectionRoute = ({ component: Component, user, permission, ...rest }) => {
  const canAccess = user.access.find((e) => e.name === permission);
  return (
    <Route
      {...rest}
      render={(props) =>
        canAccess ? (
          <React.Suspense fallback={<div></div>}>
            <Component {...props} />
          </React.Suspense>
        ) : (
          <Redirect to={RouteNames.dashboard.path} />
        )
      }
    />
  );
};
class PermissionRouter extends Component {
  componentDidMount() {
    const setLayoutData = this.props.setLayoutData;
    setLayoutData();
  }
  render() {
    const { user } = this.props;
    return (
      <Switch>
        <Route
          path={RouteNames.dashboard.path}
          render={(props) => (
            <React.Suspense fallback={<div></div>}>
              <Dashboard {...props} setLayoutData={this.props.setLayoutData} />
            </React.Suspense>
          )}
        />
        {user &&
          allRouters.map((route, index) => (
            <SectionRoute user={user} {...route} key={index} />
          ))}
        <Route
          exact
          path="/"
          render={(props) => <Redirect to={RouteNames.dashboard.path} />}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (store) => {
  const { user } = store.get("auth").toJS();
  return {
    user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PermissionRouter);
