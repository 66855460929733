import CSS from "csstype";
import { PropagateLoader } from "react-spinners";

const style: CSS.Properties = {
  width: "inherit",
  height: "200px",
  textAlign: "center",
  alignItems: "center",
  padding: " 10% 50% ",
};

export default () => (
  <div className="sweet-loading">
    <PropagateLoader size={35} color={"#0caee3"} loading />
  </div>
);

export const Beater = () => (
  <div className="sweet-loading mt-3" style={style}>
    <span></span>
    <PropagateLoader size={30} color={"#0caee3"} loading />
  </div>
);
