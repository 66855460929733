import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Spinner } from "../../components/Loading";
import injectReducer from "../../core/store/injectReducer";
import reducer from "./reducer";
import "./styles.scss";

class Loader extends Component {
  componentDidMount() {
    const localStorageTheme = localStorage.getItem("theme");
    if (localStorageTheme !== null && localStorageTheme === "dark") {
      document.body.classList.toggle("dark-theme");
    }
  }

  render() {
    const { isConnecting } = this.props;
    if (!isConnecting) {
      return <div />;
    }

    return (
      <div>
        <div className="nav-absolute-background" />
        <div className="absolute-background">
          <Spinner />
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  isConnecting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isConnecting: state.get("loader").get("isConnecting"),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({ key: "loader", reducer });

export default compose(withReducer, withConnect)(Loader);
