import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import Select from "react-select";
import { Modal } from "reactstrap";
import Api from "../../../core/Api";
import "jspdf-autotable";
import RequestAccess from "./RequestAccess";
import * as actions from "../../Dashboard/actions";
import axios from "axios";
import { getData, saveData } from "../../../core/utils/localStorage";

class ReportForm extends PureComponent {
  state = {
    selectedOption: null,
    options: [],
    orgName: "",
    isValid: false,
  };

  componentDidMount() {
    const persist = getData("access_token");
    axios
      .get(`${Api.request.ROOT_API_URL}/currentorg/`, {
        headers: {
          Authorization: `Bearer ${persist}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ orgName: res.data });
        const { orgs } = this.props;
        let options = [];
        orgs.forEach((element) => {
          if (element != res.data.toLowerCase()) {
            let tempObject = {};
            tempObject.value = element;
            tempObject.label = element;
            options.push(tempObject);
          }
        });
        this.setState({ options });
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onClose();
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.value, isValid: true });
  };

  onhandleRequest = () => {
    this.setState({ requestModal: true });
  };

  onCloseRequest = () => {
    this.setState({ requestModal: false });
  };

  onSave = (org) => {
    const persist = getData("access_token");
    if (org != null) {
      axios
        .get(`${Api.request.ROOT_API_URL}/switchorgs/${org}`, {
          headers: {
            Authorization: `Bearer ${persist}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          saveData("access_token", res.data.access_token);
        })
        .then(() => {
          this.props.onClose();
          window.location.reload();
        });
    }
  };

  render() {
    const { options, selectedOption, orgName } = this.state;
    const { onClose, user } = this.props;

    return (
      <div>
        <Modal
          isOpen={true}
          id="exampleModal-4"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="ModalLabel"
          aria-hidden="true"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLabel">
              Switch Organization
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group mt-0 mb-0">
              <h5
                htmlFor="recipient-name"
                className="col-form-label text-primary"
              >
                <strong>Current: </strong> {orgName}
              </h5>
              <h5
                htmlFor="recipient-name"
                className="col-form-label text-primary"
              >
                <strong>Switch To</strong>
              </h5>
              <Select
                className="text-dark"
                onChange={this.handleChange}
                options={options}
                placeholder="Select Org"
              />
              <span className="txt-note">Choose an Organization to view</span>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            {options.length > 0 && (
              <button
                type="button"
                className="btn btn-outline-success shadow-none"
                style={{ borderRadius: 7 }}
                onClick={() => this.onSave(selectedOption)}
                disabled={!this.state.isValid}
              >
                Switch
              </button>
            )}
            <button
              type="button"
              className="btn btn-outline-warning"
              style={{ borderRadius: 7 }}
              onClick={this.onhandleRequest}
            >
              Request Access
            </button>

            {/* <button
              type="button"
              className="btn btn-outline-secondary"
              style={{ borderRadius: 7 }}
              onClick={onClose}
            >
              Cancel
            </button> */}
            {this.state.requestModal && (
              <RequestAccess
                isOpen={this.state.requestModal}
                fromemail={user.email}
                onClose={this.onCloseRequest}
              />
            )}
          </div>
        </Modal>

        <div className="modal-backdrop fade show" />
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({
  getDashboardData: () => dispatch(actions.getDashboardData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm);
