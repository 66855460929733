export const GET_DASHBOARD_DATA = "desion/dashboard/get_dashboard_data";
export const GET_DASHBOARD_DATA_SUCCESS =
  "desion/dashboard/get_dashboard_data_success";
export const GET_DASHBOARD_DETAIL = "desion/dashboard/get_dashboard_detail";

export const GET_THREATMAP_DETAIL = "desion/dashboard/get_threatmap_detail";
export const GET_THREATMAP_DATA_SUCCESS =
  "desion/dashboard/get_threatmap_data_success";
export const CREATE_DEVICE = "desion/applications/devices/create_device";
export const USER_DETAIL = "desion/dashboard/user_detail";
export const USER_DETAIL_SUCCESS = "desion/dashboard/user_detail_success";

export const REVOKE_SIGN_IN_REQUEST = "desion/user/revoke/signin/request";
export const REVOKE_SIGN_IN_REQUEST_SUCCESS =
  "desion/user/revoke/signin/request/success";
export const REVOKE_SIGN_IN_REQUEST_FAILURE =
  "desion/user/revoke/signin/request/failure";

export const WIDGET_LAYOUT_REQUEST = "desion/user/widget/layout/request";
export const WIDGET_LAYOUT_REQUEST_SUCCESS =
  "desion/user/widget/layout/request/success";
export const WIDGET_LAYOUT_REQUEST_FAILURE =
  "desion/user/widget/layout/request/failure";

export const SET_USERS_LIST = "desion/management/set_users_list";

export const CREATE_JIRA_REQUEST = "desion/user/create/jira/request";
export const CREATE_JIRA_REQUEST_SUCCESS =
  "desion/user/create/jira/request/success";
export const CREATE_JIRA_REQUEST_FAILURE =
  "desion/user/cerate/jira/request/failure";

export const BLOCK_IP_REQUEST = "desion/user/block/ip/request";
export const BLOCK_IP_REQUEST_SUCCESS = "desion/user/block/ip/request/success";
export const BLOCK_IP_REQUEST_FAILURE = "desion/user/block/ip/request/failure";

export const GET_LOGINS_REQUEST = "desion/user/get/login/request";
export const GET_LOGINS_REQUEST_SUCCESS =
  "desion/user/get/login/request/success";
export const GET_LOGINS_REQUEST_FAILURE =
  "desion/user/get/login/request/failure";

export const GET_TOP_URLS_REQUEST = "desion/user/get/topUrls/request";
export const GET_TOP_URLS_REQUEST_SUCCESS =
  "desion/user/get/topUrls/request/success";
export const GET_TOP_URLS_REQUEST_FAILURE =
  "desion/user/get/topUrls/request/failure";

export const ARCHIVE_DEVICE_REQUEST = "desion/user/archive/device/request";
export const ARCHIVE_DEVICE_REQUEST_SUCCESS =
  "desion/user/archive/device/request/success";
export const ARCHIVE_DEVICE_REQUEST_FAILURE =
  "desion/user/archive/device/request/failure";

export const CREATE_JIRA_ANOMALY_REQUEST =
  "desion/user/create/jira/anomaly/request";
export const CREATE_JIRA_ANOMALY_REQUEST_SUCCESS =
  "desion/user/create/jira/anomaly/request/success";
export const CREATE_JIRA_ANOMALY_REQUEST_FAILURE =
  "desion/user/cerate/jira/anomaly/request/failure";

export const GET_JIRA_DETAILS_REQUEST = "desion/user/get/jira/details/request";
export const GET_JIRA_DETAILS_REQUEST_SUCCESS =
  "desion/user/get/jira/details/request/success";
export const GET_JIRA_DETAILS_REQUEST_FAILURE =
  "desion/user/get/jira/details/request/failure";

export const SETTING_KEYWORD_REQUEST = "desion/settings/keyword/request";
export const SETTING_KEYWORD_REQUEST_SUCCESS =
  "desion/settings/keyword/request/success";
export const SETTING_KEYWORD_REQUEST_FAILURE =
  "desion/settings/keyword/request/failure";

export const SETTING_LOCAL_KEYWORD_REQUEST =
  "desion/settings/local_keyword/request";
export const SETTING_LOCAL_KEYWORD_REQUEST_SUCCESS =
  "desion/settings/local_keyword/request/success";
export const SETTING_LOCAL_KEYWORD_REQUEST_FAILURE =
  "desion/settings/local_keyword/request/failure";

export const SETTING_IP_REQUEST = "desion/settings/Ip/request";
export const SETTING_IP_REQUEST_FAILURE = "desion/settings/Ip/request/failure";
export const SETTING_IP_REQUEST_SUCCESS = "desion/settings/Ip/request/success";

export const SETTING_ADDKEYWORD_REQUEST = "desion/settings/ADDkeyword/request";
export const SETTING_ADDKEYWORD_REQUEST_FAILURE =
  "desion/settings/ADDkeyword/request/failure";
export const SETTING_ADDKEYWORD_REQUEST_SUCCESS =
  "desion/settings/ADDkeyword/request/success";

export const SETTING_ADDAWSKEYWORD_REQUEST =
  "desion/settings/ADDAwskeyword/request";
export const SETTING_ADDAWSKEYWORD_REQUEST_FAILURE =
  "desion/settings/ADDAwskeyword/request/failure";
export const SETTING_ADDAWSKEYWORD_REQUEST_SUCCESS =
  "desion/settings/ADDAwskeyword/request/success";

export const SETTING_REMOVEKEYWORD_REQUEST =
  "desion/settings/Removekeyword/request";
export const SETTING_REMOVEKEYWORD_REQUEST_FAILURE =
  "desion/settings/Removekeyword/request/failure";
export const SETTING_REMOVEKEYWORD_REQUEST_SUCCESS =
  "desion/settings/Removekeyword/request/success";

export const SETTING_ADDIP_REQUEST = "desion/settings/AddIp/request";
export const SETTING_ADDIP_REQUEST_FAILURE =
  "desion/settings/AddIp/request/failure";
export const SETTING_ADDIP_REQUEST_SUCCESS =
  "desion/settings/AddIp/request/success";
