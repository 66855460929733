import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../../components/private-route";
import RouteNames from "./RouteNames";
import Layout from "../Layouts";
import LoginPage from "../Login";
import PermissionRouter from "./PermissionRouter";
const wrappedRoutes = () => (
  <Layout>
    <PermissionRouter />
  </Layout>
);

const Router = () => (
  <Switch>
    <Route exact path={RouteNames.login.path} component={LoginPage} />
    <PrivateRoute path="" component={wrappedRoutes} />
  </Switch>
);

export default Router;
