import * as CONSTANTS from "./constants";

export const getDashboardData = () => ({
  type: CONSTANTS.GET_DASHBOARD_DATA,
});
export const getThreatmapDetail = (type) => ({
  type: CONSTANTS.GET_THREATMAP_DETAIL,
  payload: type,
});

export const getDashboardDetail = (type) => ({
  type: CONSTANTS.GET_DASHBOARD_DETAIL,
  payload: type,
});

export const getLogins = (payload, cmt, cb) => ({
  type: CONSTANTS.GET_LOGINS_REQUEST,
  payload,
  cmt,
  cb,
});

export const topURLs = (cmt, cb) => ({
  type: CONSTANTS.GET_TOP_URLS_REQUEST,
  cmt,
  cb,
});

export const createDevice = (appId, device, cb) => ({
  type: CONSTANTS.CREATE_DEVICE,
  payload: {
    appId,
    device,
    cb,
  },
});
export const archiveDevice = (payload, cb) => ({
  type: CONSTANTS.ARCHIVE_DEVICE_REQUEST,
  payload,
  cb,
});

export const createJira = (payload, cmt, cb) => ({
  type: CONSTANTS.CREATE_JIRA_REQUEST,
  payload,
  cmt,
  cb,
});

export const getJiraDetails = (payload, cb) => ({
  type: CONSTANTS.GET_JIRA_DETAILS_REQUEST,
  payload,
  cb,
});

export const createJiraAnomaly = (payload, cmt, cb) => ({
  type: CONSTANTS.CREATE_JIRA_ANOMALY_REQUEST,
  payload,
  cmt,
  cb,
});

export const blockIp = (payload, cb) => ({
  type: CONSTANTS.BLOCK_IP_REQUEST,
  payload,
  cb,
});

export const getWidgetLayout = () => ({
  type: CONSTANTS.WIDGET_LAYOUT_REQUEST,
});

export const userDetails = (userId) => ({
  type: CONSTANTS.USER_DETAIL,
  payload: {
    userId,
  },
});

export function revokeSignIn(payload) {
  return {
    type: CONSTANTS.REVOKE_SIGN_IN_REQUEST,
    payload,
  };
}
