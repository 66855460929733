import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../../core/Api";
import { push } from "connected-react-router";
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE } from "../App/constants";
import { toast } from "react-toastify";
import { saveData } from "../../core/utils/localStorage";

function* syncLoginSaga(action) {
  const {
    payload: { username, secret },
    callback,
  } = action;
  try {
    let res = null;
    /*this should not be enabled
    if (username === 'tester' && secret === 'tester') {
      res = {data: {result: 'success'}}
      // res = {data: {access_token: 'tester'}}
    } else {*/
    res = yield call(Api.callapi.login, { username, secret });
    //}
    if (res.data && res.data["login result"] === "fail") {
      throw new Error("email or password is incorrect");
    }

    const access_token = new Date().getTime();
    saveData("access_token", res.data.access_token);
    yield put({
      type: LOGIN_SUCCESS,
      payload: { access_token: access_token },
    });
    yield put(push("/dashboard"));
    toast("Login Successful!, Rendering Widgets", {
      delay: 500,
      timeout: 2000,
      type: "info",
    });
  } catch (err) {
    callback(false);
    yield put({ type: LOGIN_FAILURE });
  }
}

export default function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, syncLoginSaga);
}
