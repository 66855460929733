import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Modal } from "reactstrap";
import { compose } from "redux";
import { ColHeader } from "../../../components/table";

const tableHeaders = [{ colRef: "name", colLabel: "Widget" }];

const decisionTable = [{ colRef: "action", colLabel: "Show / Hide" }];

const AlertRow = ({ row, key, onClick }) => {
  return (
    <tr className="bg-white">
      {decisionTable.map((item) => (
        <td key={item.colLabel}>{AccessSwitcher(row, onClick)}</td>
      ))}
      {tableHeaders.map((item) => (
        <td key={item.colLabel}>{row.name}</td>
      ))}
    </tr>
  );
};

const AccessSwitcher = (alert, onClick) => {
  return (
    <div
      style={{ marginLeft: 10 }}
      className={`d-flex ${
        alert.display === "active" ? "text-success" : "text-danger"
      }`}
    >
      <Switch
        onChange={onClick}
        checked={alert.display === "active"}
        onColor="#19c0ff"
        offColor="#f0483c"
        height={22}
        width={46}
      />
    </div>
  );
};

class WidgetUpdate extends React.Component {
  state = {
    widgets: this.props,
    selectedOption: null,
    comments: "",
    sortBy: "name",
  };

  componentDidMount() {}

  onSort = (colName) => {
    const { sortBy } = this.state;
    const colDir = sortBy && sortBy.colDir === "ASC" ? "DESC" : "ASC";
    this.setState({ sortBy: { colName: colName, colDir }, pageNumber: 0 });
  };

  handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption: selectedOption.value });
  };

  render() {
    const { onClose, widgets } = this.props;
    const { sortBy } = this.state;
    const widgetNames = JSON.parse(widgets.data);
    return (
      <Modal
        style={{ minWidth: "25%" }}
        isOpen={true}
        id="exampleModal-4"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ModalLabel"
        aria-hidden="true"
      >
        <strong>
          <div className="modal-header justify-content-center">
            <h5 className="modal-title" id="ModalLabel">
              Widget List
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </strong>
        <div className="modal-body">
          <div className="form-group mt-3 mb-0">
            <table id="sortable-table-2" className="table">
              <thead>
                {decisionTable.map((item) => (
                  <ColHeader
                    onSort={this.onSort}
                    colRef={item.colRef}
                    colLabel={item.colLabel}
                    sortBy={sortBy}
                    key={item.colRef}
                  />
                ))}
                {tableHeaders.map((item) => (
                  <ColHeader
                    onSort={this.onSort}
                    colRef={item.colRef}
                    colLabel={item.colLabel}
                    sortBy={sortBy}
                    key={item.colRef}
                  />
                ))}
              </thead>
              <tbody>
                {widgetNames.map((k, index) => (
                  <AlertRow row={k} key={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            className="btn btn-info"
            onClick={onClose}
          >
            Edit Widgets
          </button>
          <button onClick={onClose} className="btn btn-primary" type="submit">
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (store) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(WidgetUpdate);
